<template>
    <div>
        <!-- notes -->
        <b-form-group
            id="group-record.locale.notes"
            :label="$t('form.notes')"
            label-for="record.locale.notes"
            description=""

            class="col-sm-12 p-0"
            label-class="mb-0"
        >
            <vue-editor
                class="editor-red"
                v-model="record.notes"
            ></vue-editor>
        </b-form-group>
    </div>
</template>

<script>
export default {
name: "FormOrganisationLocaleNotes",
        components: {},
        props: {
            locale: '',
            record: {},
        },
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}
</script>

<style scoped>

</style>
