<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        scrollable
        size="xl"
        @ok="formSave"
    >
        <!--        @show="modalOpen"-->
        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab user -->
                        <b-tab :active="!record.id > 0">
                            <template #title>
                                <b-icon v-if="tabUserValidity"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$tc('admin.user.main', 0)}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <b-col>
                                        <!-- user_id -->
                                        <b-form-group
                                            :label="$tc('admin.user.main', 0)"
                                            class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                            description=""
                                            label-class="mb-0"
                                            label-for="user_id"
                                        >
                                            <b-form-select
                                                id="user_id"
                                                v-model="record.user_id"
                                                :options="getUsers"
                                                :state="userValidity"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                required
                                            ></b-form-select>
                                            <b-form-invalid-feedback :state="userValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- tab basic -->
                        <b-tab :active="record.id > 0">
                            <template #title>
                                <b-icon v-if="tabBasicValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <!-- column a -->
                                    <b-col cols="12" lg="6">
                                        <b-form-group
                                            :label="$tc('form.basic.data', 1)"
                                            class="mb-2"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- organisation official name -->
                                            <b-form-group
                                                :label="$t('organisation.name_official')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="organisation_full_name"
                                            >
                                                <b-form-input
                                                    id="organisation_full_name"
                                                    v-model="record.name.full_name"
                                                    :placeholder="$t('organisation.name_official_placeholder')"
                                                    :state="full_nameValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    required
                                                    type="text"
                                                ></b-form-input>
                                                <b-form-invalid-feedback :state="full_nameValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <!-- organisation commercial name -->
                                            <b-form-group
                                                :label="$t('organisation.name_commercial')"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                description=""
                                                label-class="mb-0"
                                                label-for="organisation_name"
                                            >
                                                <b-form-input
                                                    id="organisation_name"
                                                    v-model="record.name.name"
                                                    :placeholder="$t('organisation.name_commercial_placeholder')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                            <!-- organisation type -->
                                            <b-form-group
                                                :label="$t('organisation.type_organisation')"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                description=""
                                                label-class="mb-0"
                                                label-for="org_type"
                                            >
                                                <b-form-select
                                                    id="org_type"
                                                    v-model="record.org_type_id"
                                                    :options="getOrgTypes"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                            </b-form-group>
                                            <!-- taxpayer type -->
                                            <b-form-group
                                                :label="$tc('register.taxpayer_type', 1)"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                description=""
                                                label-class="mb-0"
                                                label-for="taxpayer_type"
                                            >
                                                <b-form-select
                                                    id="taxpayer_type"
                                                    v-model="record.taxpayer_type_id"
                                                    :options="getTaxpayerTypes"
                                                    :state="taxpayerTypeIdValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    @change="changeTaxpayerType()"
                                                ></b-form-select>
                                                <b-form-invalid-feedback :state="taxpayerTypeIdValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <!-- taxpayer -->
                                            <b-form-group
                                                class="m-0"
                                            >
                                                <b-form-radio-group
                                                    v-model="record.taxpayer"
                                                    :options="getTaxpayerOptions"
                                                    :state="taxpayerValidity"
                                                    class="mt-2"
                                                ></b-form-radio-group>
                                                <b-form-invalid-feedback :state="taxpayerValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <!-- tax_number -->
                                            <b-form-group
                                                :label="$t('organisation.tax_number')"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                description=""
                                                label-class="mb-0"
                                                label-for="tax_number"
                                            >
                                                <b-form-input
                                                    id="tax_number"
                                                    v-model="record.tax_number"
                                                    :placeholder="$t('organisation.tax_number_placeholder')"
                                                    :state="tax_numberValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-input>
                                                <b-form-invalid-feedback :state="tax_numberValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <!-- liaison -->
                                            <b-form-group
                                                :label="$t('organisation.liaison')"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                label-class=" mb-0"
                                                label-for="liaison"
                                                label-size="md"
                                            >
                                                <b-form-input
                                                    id="liaison"
                                                    v-model="record.extended.liaison"
                                                    :placeholder="$t('organisation.liaison_placeholder')"
                                                    :tooltip="$t('organisation.liaison_tooltip')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                    <!-- column b -->
                                    <b-col cols="12" lg="6">
                                        <!-- bank account -->
                                        <b-form-group
                                            :label="$t('organisation.bank_account')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- bank -->
                                            <b-form-group
                                                :label="$t('organisation.bank_name')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="bank"
                                            >
                                                <b-form-select
                                                    v-if="!getBanks.custom"
                                                    id="bank"
                                                    v-model="record.bank_id"
                                                    :options="getBanks.banks"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                            </b-form-group>
                                            <!-- bank other -->
                                            <b-form-group
                                                v-if="getBanks.custom || record.bank_id === -1"
                                                :label="record.bank_id === -1 ? $t('organisation.bank_name_other') : $t('organisation.bank_name')"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                description=""
                                                label-class="mb-0"
                                                label-for="bank_other"
                                            >
                                                <b-form-input
                                                    id="bank_other"
                                                    :placeholder="$t('organisation.bank_name_placeholder')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-input>
                                            </b-form-group>
                                            <!-- bank account -->
                                            <b-form-group
                                                :label="$t('organisation.bank_account_number')"
                                                class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                                description=""
                                                label-class="mb-0"
                                                label-for="bank_account"
                                            >
                                                <b-form-input
                                                    id="bank_account"
                                                    v-model="record.bank_account.account"
                                                    :placeholder="$t('organisation.bank_account_number_placeholder')"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-input>
                                            </b-form-group>
                                        </b-form-group>
                                        <!-- farm  -->
                                        <label
                                            class="mb-0 mt-4 font-weight-bold pt-0"
                                            size="md"
                                        >
                                            {{$tc('organisation.data_farm', 1)}}
                                            <b-button
                                                v-b-toggle.collapse-help-farm
                                                class="ml-1 py-0" size="sm"
                                                variant="info"
                                            >?
                                            </b-button>
                                        </label>
                                        <b-collapse id="collapse-help-farm">
                                            <b-card class="bg-info">
                                                <div v-html="$t('organisation.data_farm_info')"></div>
                                            </b-card>
                                        </b-collapse>
                                        <!-- farm number -->
                                        <b-form-group
                                            :label="$t('organisation.farm_number')"
                                            class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                            description=""
                                            label-class="mb-0"
                                            label-for="farm_number"
                                        >
                                            <b-form-input
                                                id="farm_number"
                                                v-model="record.farm.number"
                                                :placeholder="$t('organisation.farm_number_placeholder')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                            ></b-form-input>
                                        </b-form-group>
                                        <!-- farm acreage -->
                                        <b-form-group
                                            :label="$t('organisation.farm_acreage')"
                                            class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                            description=""
                                            label-class="mb-0"
                                            label-for="farm_acreage"
                                        >
                                            <b-input-group append="ha">
                                                <b-form-input
                                                    id="farm_acreage"
                                                    v-model.number="record.farm.acreage"
                                                    class="mb-sm-0 text-right no-spinners"
                                                    placeholder="..."
                                                    step="0.01"
                                                    type="number"
                                                    variant="primary"
                                                    @keypress="__onlyDecimalKey($event)"
                                                ></b-form-input>
                                            </b-input-group>
                                        </b-form-group>
                                        <!-- farm is tourism -->
                                        <b-form-checkbox
                                            id="checkbox_tourism"
                                            v-model="record.farm.is_tourism"
                                            class="pt-2"
                                            name="Tourism"
                                        >
                                            {{$t('organisation.farm_tourism')}}
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- tab address -->
                        <b-tab>
                            <template #title>
                                <b-icon v-if="tabAddressValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('organisation.tab.address')}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <!-- column a -->
                                    <b-col cols="12" lg="6">
                                        <!--address-->
                                        <b-form-group
                                            :label="$t('organisation.address')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <form-address
                                                :data="data.states"
                                                :record.sync="getAddress"
                                            ></form-address>
                                        </b-form-group>
                                    </b-col>
                                    <!-- column b -->
                                    <b-col cols="12" lg="6">
                                        <!-- Other -->
                                        <b-form-group
                                            :label="$t('organisation.address_other')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- web page -->
                                            <b-form-group
                                                :label="$t('organisation.webpage')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                label-class=" mb-0"
                                                label-for="organisation_url"
                                                label-size="md"
                                            >
                                                <b-input-group>
                                                    <b-form-input
                                                        id="organisation_url"
                                                        ref="ref-webpage"
                                                        v-model="record.extended.webpage"
                                                        :placeholder="$t('organisation.webpage_placeholder')"
                                                        :state="urlValidity"
                                                        :tooltip="$t('organisation.webpage_tooltip')"
                                                        class="mb-2 mr-sm-0 mb-sm-0"
                                                        type="url"
                                                    ></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button
                                                            :disabled="record.extended.webpage === null || record.extended.webpage.length < 4"
                                                            :href="record.extended.webpage"
                                                            target="_blank"
                                                            variant="info"
                                                        >
                                                            <b-icon icon="link45deg"></b-icon>
                                                        </b-button>
                                                    </b-input-group-append>
                                                    <b-form-invalid-feedback :state="urlValidity">
                                                        {{$t('form.url_validity_message')}}
                                                    </b-form-invalid-feedback>
                                                </b-input-group>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- contacts -->
                        <b-tab :title="$t('organisation.tab.contact')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('organisation.tab.contact')"
                                    class="mb-0"
                                    label-class="font-weight-bold pt-0"
                                    label-size="md"
                                >
                                    <!-- phones -->
                                    <b-form-group
                                        :label="$t('organisation.phone')"
                                        class="mb-2 mr-sm-2 mb-sm-0 pt-0"
                                        description=""
                                        label-class="mb-0"
                                        label-for="data.phone"
                                    >
                                        <form-table-values
                                            id="data.phone"
                                            :column_a_text="$t('form.table_values.person')"
                                            :column_b_text="$t('form.table_values.description')"
                                            :column_c_text="$t('form.table_values.number')"
                                            :extended="true"
                                            :record="recordClone.contact"
                                            input_c_type="tel"
                                            json_key="phone"
                                            @eventUpdateRecord="contactUpdate"
                                        />
                                    </b-form-group>
                                    <!-- email -->
                                    <b-form-group
                                        :label="$t('organisation.email')"
                                        class="mb-2 mr-sm-2 mb-sm-0 pt-0"
                                        description=""
                                        label-class="mb-0"
                                        label-for="data.email"
                                    >
                                        <form-table-values
                                            id="data.email"
                                            :column_a_text="$t('form.table_values.person')"
                                            :column_b_text="$t('form.table_values.description')"
                                            :column_c_text="$t('form.table_values.address')"
                                            :extended="true"
                                            :record="recordClone.contact"
                                            input_c_type="email"
                                            json_key="email"
                                            @eventUpdateRecord="contactUpdate"
                                        />
                                    </b-form-group>
                                    <!-- internet contacts -->
                                    <b-form-group
                                        :label="$t('organisation.internet_contact')"
                                        class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                        description=""
                                        label-class="mb-0"
                                        label-for="data.internetContact"
                                    >
                                        <form-table-values
                                            id="data.internetContact"
                                            :column_a_text="$t('form.table_values.person')"
                                            :column_b_text="$t('form.table_values.description')"
                                            :column_c_text="$t('form.table_values.address')"
                                            :extended="true"
                                            :record="recordClone.contact"
                                            input_c_type="text"
                                            json_key="internet_contact"
                                            @eventUpdateRecord="contactUpdate"
                                        />
                                    </b-form-group>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- employees -->
                        <b-tab :title="$t('organisation.tab.employees')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('organisation.tab.employees')"
                                    class="mb-0"
                                    label-class="font-weight-bold pt-0"
                                    label-size="md"
                                >
                                </b-form-group>
                                in progress...
                            </b-card-text>
                        </b-tab>
                        <!-- introduction -->
                        <b-tab :title="$t('organisation.tab.introduction')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.locale.locale')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <b-tabs card pills>
                                            <b-tab v-for="locale in data.locale"
                                                   :active="locale.locale === $i18n.locale"
                                                   :title="locale.locale" style="background: white">
                                                <form-organisation-locale-introduction
                                                    :locale="locale.locale"
                                                    :record.sync="getLocaleIntroduction[locale.locale]"
                                                ></form-organisation-locale-introduction>
                                            </b-tab>
                                        </b-tabs>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- pictures -->
                        <b-tab :title="$t('form.tab.picture')">
                            <PictureManagerDeprecated
                                :picture-manager-bus="pictureManagerBus"
                                @eventUpdate="eventPictureManagerUpdate"
                            >
                            </PictureManagerDeprecated>
                        </b-tab>
                        <!-- certificates -->
                        <b-tab :title="$t('organisation.tab.certificate')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('organisation.tab.certificate')"
                                    class="mb-0"
                                    label-class="font-weight-bold pt-0"
                                    label-size="md"
                                >
                                    <form-table-certificates
                                        :record="recordClone.certificate"
                                        :states="data.statesNameActive"
                                        :tags="data.tags"
                                        @eventUpdateRecord="certificateUpdate"
                                    ></form-table-certificates>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- partnership -->
                        <b-tab :title="$t('organisation.tab.partnership')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('organisation.tab.partnership')"
                                    class="mb-0"
                                    label-class="font-weight-bold pt-0"
                                    label-size="md"
                                >
                                    <div v-for="data in getPartnershipTypes">
                                        <b-form-checkbox
                                            :id="`partnershipType_${data.value}`"
                                            v-model="record.partnership[data.value]"
                                            class="pt-0 pl-4 ml-3 mb-2"
                                        >
                                            {{data.text}}
                                        </b-form-checkbox>
                                    </div>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- notes -->
                        <b-tab :title="$t('form.tab.notes')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.locale.locale')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <b-tabs card pills>
                                            <b-tab v-for="locale in data.locale"
                                                   :active="locale.locale === $i18n.locale"
                                                   :title="locale.locale" style="background: white">
                                                <form-organisation-locale-notes
                                                    :locale="locale.locale"
                                                    :record.sync="getLocaleNotes[locale.locale]"
                                                ></form-organisation-locale-notes>
                                            </b-tab>
                                        </b-tabs>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
        <!-- alert error-->
        <!--        <b-alert
                    v-if="errors !== null"
                    :show="errors.message != null"
                    class="mb-0"
                    dismissible
                    variant="danger"
                    @dismissed="resetErrors"
                >
                    <div v-html="__formatErrorMessage(errors.message)"></div>
                </b-alert>-->
    </b-modal>
</template>

<script>
import Vue from "vue";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import FormAddress from "@/components/general/elements/formElements/FormAddress";
import FormTableValues from "@/components/general/elements/formElements/FormTableValues";
import FormOrganisationLocaleIntroduction from "@/components/admin/organisation/FormOrganisationLocaleIntroduction";
import FormOrganisationLocaleNotes from "@/components/admin/organisation/FormOrganisationLocaleNotes";
import FormTableCertificates from "@/components/general/elements/formElements/certificate/FormTableCertificates";
import PictureManagerDeprecated
    from "@/components/general/elements/pictures/pictures_manager_deprecated/PictureManagerDeprecated";

export default {
    name: "ModalFormOrganisation",
    components: {
        PictureManagerDeprecated,
        FormTableCertificates,
        FormOrganisationLocaleNotes,
        FormOrganisationLocaleIntroduction, FormTableValues, FormAddress, FormIdActive
    },
    props: {
        localeKey: null,
        uri: null,
        form: {},
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            division: 'organisation',
            // <-- record -->
            recordDefault: {
                id: 0,
                user_id: null,
                active: true,
                order: 0,
                org_type_id: null,
                taxpayer_type_id: null,
                name: {
                    full_name: null,
                    name: null,
                },
                tax_number: null,
                bank_id: null,
                bank_account: {
                    account: null,
                    bank_custom: null,
                },
                address: {
                    state_id: null,
                    postOffice_id: null,
                    region_id: null,
                    street: null,
                    full: null
                },
                contact: {},
                certificate: [],
                partnership: {},
                locale: {},
                farm: {
                    acreage_unit: 'ha',
                },
                extended: {
                    liaison: null,
                    webpage: null,
                },
                pictures_order: [],
                pictures: [],
            },
            record: null,
            recordClone: {},
            // <-- validity -->
            validityDefault: {
                check: false,
            },
            validity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: null,
            pictureManagerBus: new Vue(),
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            // this.recordId = recordId;
            this.form.id = recordId;
            this.modalOpen();
        });
    },
    destroyed() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'validity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        contactUpdate(data) {
            this.$set(this.record.contact, data.key, data.values);
        },
        certificateUpdate(data) {
            this.$set(this.record, 'certificate', data.values);
        },
        changeTaxpayerType() {
            let predefined_taxpayer = this.getTaxpayerTypes[this.record.taxpayer_type_id];
            predefined_taxpayer = predefined_taxpayer === undefined ? null : predefined_taxpayer.taxpayer;

            let isPredefined = typeof predefined_taxpayer === 'boolean';

            if (!isPredefined) {
                this.$set(this.record, 'taxpayer', null);
            }
        },
        // <-- table -->
        tableRowDetail(locale) {
            // let detail = null;
            // Object.keys(locale).find((n) => {
            //     if (n === this.$i18n.locale) {
            //         detail = locale[n].notes;
            //     }
            // });
            // return detail;
        },
        // <-- modal -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.form.id > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- form -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            this.validity.check = true;
            let validity = true;
            // // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.validity).forEach(key => {
                    if (key !== 'check' && this.validity[key] === false) {
                        validity = false;
                    }
                });
                validity = validity && this.$refs.form.checkValidity();
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData();
                        return;
                    }
                    this.baseInsertFormData();
                }
            });
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            // this.$nextTick(() => {
            this.$set(this, 'record', this.__clone(this.recordDefault));
            this.$set(this, 'recordClone', this.__clone(this.record));
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignDataActive`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);

                    this.$bvModal.show('ModalFormOrganisation');
                    // <-- picture -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures);
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.form.id}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    let record = response.data.record;
                    this.__mergeObjectDeep(this.recordDefault, record);
                    this.$set(this, 'record', record);
                    this.$set(this, 'recordClone', this.__clone(this.record));

                    this.$bvModal.show('ModalFormOrganisation');
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures)
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                });
        },
        // <-- events pictureManager -->
        eventPictureManagerUpdate(data) {
            this.$set(this.record, 'pictures_order', data.pictures_order);
            this.$set(this.record, 'pictures', data.pictures);
        },
        // <-- base -->
        baseInsertFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('locale', JSON.stringify(this.$i18n.locale));
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/form_data_insert', formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdateFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            };
            // <-- form data -->
            let formData = new FormData();
            let imagesUpdate = [];
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    if (picture.id === 0) {
                        formData.append('division', this.division);
                        formData.append('files[' + index + ']', picture.file);
                        formData.append('keys[' + index + ']', picture.key);
                    } else {
                        imagesUpdate.push(picture);
                    }
                });
            }
            formData.append('imagesUpdate', JSON.stringify(imagesUpdate));
            // <-- prepare data for axios -->
            formData.append('locale', JSON.stringify(this.$i18n.locale));
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/form_data_update' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.form.id <= 0 || record === null || record.name === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.name.name;
                name = name === null ? record.name.full_name : name;
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            }
        },
        // <-- data -->
        getUsers() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.users)) {
                return array;
            }
            // <--  -->
            let items = this.data.users;
            items.forEach(item => {
                array.push({
                    text: `${item.email}`,
                    value: item.id
                });
            });
            // <--  -->
            return array;
        },
        getOrgTypes() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.org_types)) {
                return array;
            }
            // <--  -->
            let items = this.data.org_types;
            items.forEach(item => {
                array.push({
                    text: item.meta.name_full,
                    value: item.id
                });
            });
            // <--  -->
            return array;
        },
        getTaxpayerTypes() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.taxpayer_types)) {
                return array;
            }
            // <--  -->
            let items = this.data.taxpayer_types;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id,
                    taxpayer: item.meta.taxpayer
                });
            });
            // <--  -->
            return array;
        },
        getBanks() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data.banks)) {
                return {
                    custom: true,
                    banks: array
                };
            }
            // <--  -->
            let items = this.data.banks;
            items.forEach(item => {
                array.push({
                    text: item.meta.name,
                    value: item.id
                });
            });
            array.push(
                {
                    text: this.$t('form.other'),
                    value: -1
                }
            );
            // <--  -->
            return {
                banks: array,
                custom: false
            };
        },
        getAddress() {
            // <-- new record -->
            if (this.record.id === undefined) {
                return this.record.address;
            }

            // <-- edit record -->
            let record = this.record;
            let address = this.__clone(this.recordDefault.address);

            address.state_id = this.__isNull(record.post_office) ? record.address.state_id : record.post_office.state_id;
            address.postOffice_id = record.post_office_id;
            address.region_id = record.region_id;
            address.street = record.address.street;
            address.full = record.address.full;
            // <--  -->
            this.$set(this.record, 'address', address);
            return this.record.address;
        },
        getPartnershipTypes() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.partnership_types)) {
                return array;
            }
            // <--  -->
            let items = this.data.partnership_types;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: this.__createJsonKey(item.meta.name)
                });
            });
            // <--  -->
            return array;
        },
        getLocaleIntroduction() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        introduction: null
                    });
                }
            });
            return this.record.locale;
        },
        getLocaleNotes() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        notes: null
                    });
                }
            });
            return this.record.locale;
        },
        // <-- record -->
        getTaxpayerOptions() {
            if (this.record.taxpayer_type_id === null) {
                this.$set(this.record, 'taxpayer', null);
                return [
                    {text: this.$tc('finance.taxpayer', 0), value: true, disabled: true},
                    {text: this.$tc('finance.no_taxpayer', 0), value: false, disabled: true},
                ]
            }
            // <-- predefined taxpayer -->
            let predefined_taxpayer = this.getTaxpayerTypes[this.record.taxpayer_type_id];
            predefined_taxpayer = predefined_taxpayer === undefined ? null : predefined_taxpayer.taxpayer;

            // <-- is predefined taxpayer -->
            let isPredefined = typeof predefined_taxpayer === 'boolean';
            if (isPredefined) {
                this.$set(this.record, 'taxpayer', predefined_taxpayer);
            }
            // <--  -->
            return [
                {text: this.$tc('finance.taxpayer', 0), value: true, disabled: isPredefined},
                {text: this.$tc('finance.no_taxpayer', 0), value: false, disabled: isPredefined},
            ]
        },
        // <-- validity -->
        userValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            let validity = this.record.user_id > 0;
            return validity ? null : false;
        },
        full_nameValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            let validity = !(this.record.name.full_name === null || this.record.name.full_name.length === 0);
            return validity ? null : false;
        },
        urlValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            // <--  -->
            if (this.__isNull(this.record.extended.webpage) || this.$refs['ref-webpage'] === undefined) {
                return null;
            }
            // <--  -->
            let validity = this.$refs['ref-webpage'].checkValidity();
            return validity ? null : validity;
        },
        // <-- validity: sale -->
        taxpayerTypeIdValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.taxpayer_type_id > 0;
            return validity ? null : validity;
        },
        taxpayerValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            if (this.record.taxpayer_type_id === null) {
                return null;
            }
            // <--  -->
            let validity = this.record.taxpayer !== null;
            return validity ? null : validity;
        },
        tax_numberValidity() {
            if (this.validity === undefined || this.validity.check === false) {
                return null;
            }
            if (this.record.taxpayer !== true) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.tax_number);
            return validity ? null : validity;
        },
        // <-- tabs validity -->
        tabUserValidity() {
            let tabValidity = this.userValidity === null;
            // <--  -->
            this.$set(this.validity, 'tab_user', tabValidity);
            return this.userValidity === null ? null : !this.userValidity;
        },
        tabBasicValidity() {
            let tabValidity = true;
            // <--  -->
            tabValidity = tabValidity === false || this.full_nameValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.taxpayerTypeIdValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.taxpayerValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.tax_numberValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.validity, 'tab_basic', tabValidity);
            return tabValidity;
        },
        tabAddressValidity() {
            let tabValidity = true;
            // <--  -->
            tabValidity = tabValidity === false || this.urlValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.validity, 'tab_address', tabValidity);
            return tabValidity ? null : false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
