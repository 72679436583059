<template>
    <div>
        <b-table
            id="main-table"
            :fields="tableFields"
            :items="table.items"
            bordered
            head-row-variant="primary"
            hover
            outlined
            table-class="text-center"
            sticky-header="330px"
        >
            <!-- header add -->
            <template #head(action)="head">
                <b-icon-plus-square
                    v-b-tooltip.hover
                    :title="$t('form.default')"
                    scale="1.2"
                    style="cursor: pointer; margin-right: 3px;"
                    variant=""
                    @click="itemAdd()"
                ></b-icon-plus-square>
            </template>
            <!-- state id -->
            <template v-slot:cell(state_id)="row">
                <div class="d-flex justify-content-around">
                    <b-form-select
                        id="state_id"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="row.item.state_id"
                        :options="getStates"
                    ></b-form-select>
                </div>
            </template>
            <!-- org. name -->
            <template v-slot:cell(organisation)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.org_name"
                        :placeholder="$t('organisation.certificate_organisation_placeholder')"
                        class="text-left"
                        size="sm"
                        type="text"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>
            <!-- certificate name -->
            <template v-slot:cell(name)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.name"
                        :placeholder="$t('organisation.certificate_name_placeholder')"
                        class="text-left"
                        size="sm"
                        type="text"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>
            <!-- certificate number -->
            <template v-slot:cell(number)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model="row.item.number"
                        :placeholder="$t('organisation.certificate_number_placeholder')"
                        class="text-left"
                        size="sm"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>
            <!-- certificate expires date -->
            <template v-slot:cell(expires)="row">
                <div class="d-flex justify-content-around">
                    <b-form-input
                        v-model.date="row.item.expires"
                        class="text-center"
                        size="sm"
                        type="date"
                        variant="primary"
                    ></b-form-input>
                </div>
            </template>
            <!-- tag id -->
            <template v-slot:cell(tag_id)="row">
                <div class="d-flex justify-content-around">
                    <b-form-select
                        id="state_id"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="row.item.tag_id"
                        :options="getTags"
                    ></b-form-select>
                </div>
            </template>
            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-center align-items-center" style="height:40px">
                    <!-- delete -->
                    <b-icon-trash
                        v-if="!row.item.system"
                        v-b-tooltip.hover
                        :title="$t('form.delete')"
                        scale="1.2"
                        style="cursor: pointer; margin-left: 3px;"
                        variant="danger"
                        @click="itemDelete(row.item)"
                    ></b-icon-trash>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: "FormTableCertificates",
    components: {},
    props: {
        emptyRowsNumber: {
            type: Number,
            default() {
                return 1
            }
        },
        record: {
            type: Array,
            default() {
                return []
            }
        },
        states: {
            type: Array,
            default() {
                return []
            }
        },
        tags: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            table: {
                items: [],
                fields: [
/*                    {
                        key: 'id',
                        label: this.$t('form.id'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },*/
                    // {
                    //     key: 'state_id',
                    //     label: this.$t('organisation.state'),
                    //     sortable: true,
                    //     show: true,
                    //     thStyle: {'vertical-align': 'middle'}
                    // },
                    {
                        key: 'organisation',
                        label: this.$t('organisation.certificate_organisation'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'name',
                        label: this.$t('organisation.certificate_name'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'number',
                        label: this.$t('organisation.certificate_number'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'expires',
                        label: this.$t('organisation.certificate_expires'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },
                    {
                        key: 'tag_id',
                        label: this.$t('form.type'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        thStyle: {width: '40px'}
                    },
                ],
            },
        }
    },
    mounted() {
        this.setItems(this.record);
    },
    methods: {
        // <-- table items -->
        defaultItemAdd() {
            if (this.table.items.length > 0) {
                return;
            }
            // <--  -->
            for (let i = 0; i < this.emptyRowsNumber; i++) {
                this.itemAdd();
            }
        },
        itemAdd() {
            let index = this.table.items.length;
            this.table.items.push(
                {
                    key: this.__createUniqueKey(index),
                    id: null,
                    state_id: 157,
                    org_name: null,
                    number: null,
                    name: null,
                    expires: null,
                    tag_id: null
                }
            );
        },
        setItems(record) {
            if (record.length === 0) {
                this.table.items = [];
                this.defaultItemAdd();
                return;
            }
            // <-- set items -->
            let items = [];
            // <-- set items from record -->
            record.forEach((item, index) => {
                items.push(
                    {
                        key: this.__createUniqueKey(index),
                        index: index,
                        id: item.id,
                        state_id: item.state_id,
                        org_name: item.org_name,
                        number: item.number,
                        name: item.name,
                        expires: item.expires,
                        tag_id: item.tag_id,
                    }
                );
            });
            // <--  -->
            this.$set(this.table, 'items', items);
        },
        itemDelete(row) {
            let items = this.table.items;
            let key = row.key;

            // <-- find row index -->
            let index = -1;

            items.find((n, indexTemp) => {
                if (n.key === key) {
                    index = indexTemp;
                }
            });
            // <-- remove item -->
            this.table.items.splice(index, 1);
        },
        itemsOrder() {
            // let items = [];
            // // <-- default item -->
            // this.table.items.forEach(item => {
            //     if (item.default) {
            //         items.push(item);
            //     }
            // });
            // // <-- non default item -->
            // this.table.items.forEach(item => {
            //     if (!item.default) {
            //         items.push(item);
            //     }
            // });
            // // <--  -->
            // this.$set(this.table, 'items', items);
        },
        // <-- record -->
        setRecords() {
            let record = [];
            this.table.items.forEach((item, index) => {
                record.push({
                    index: index,
                    id: item.id,
                    state_id: item.state_id,
                    org_name: item.org_name,
                    number: item.number,
                    name: item.name,
                    expires: item.expires,
                    tag_id: item.tag_id,
                });
            });
            // <--  -->
            let data = {
                values: record
            };
            this.$emit('eventUpdateRecord', data);
        },
    },
    computed: {
        tableFields() {
            let fields = [];
            this.table.fields.forEach(field => {
                fields.push(field);
            });
            return fields;
        },
        // <--  -->
        getStates() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.states)) {
                return array;
            }
            // <--  -->
            let items = this.states;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <--  -->
            return array;
        },
        getTags() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.tags)) {
                return array;
            }
            // <--  -->
            let items = this.tags;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <--  -->
            return array;
        },
    },
    filters: {},
    watch: {
        'table.items': {
            handler: function (value, oldValue) {
                this.setRecords();
            },
            deep: true
        },
        'record': {
            handler: function (value, oldValue) {
                this.setItems(value);
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
