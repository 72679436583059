<template>
    <div>
        <!-- notes -->
        <b-form-group
            id="group-record.locale.notes"
            :label="$t('form.introduction')"
            class="col-sm-12 p-0"
            description=""
            label-class="mb-0"
            label-for="record.locale.notes"
        >
            <vue-editor
                class="editor-blue"
                v-model="record.introduction"
            ></vue-editor>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: "FormOrganisationLocaleIntroduction",
    components: {},
    props: {
        locale: '',
        record: {},
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
